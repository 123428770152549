*{
  margin: 0%;
  padding: 0%;
}

*::-webkit-scrollbar{
  width: 5px;
  height: 0.1px;
  background: transparent;
}

*::-webkit-scrollbar-thumb{
  width: 100%;
  height: 0.1%;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  background: #ff9c04;
}

#root{
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
}

.body {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

/* Header */

.Header{
  width: 101%;
  height: 16%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ff9c04;
}

.containHeader{
  width: 95%;
  height: 18%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ff9c04;
}

#contenedorHeader{
  width: 101%;
  height: 15%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #ff9c04;
  position: fixed;
  left: 0;
  top: 0;
}

.ContenedorLogo{
  width: 6%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: 100% 100%;
  margin: 0.5%;
}

.ContenedorLogo img{
  width: 60%;
  height: 7rem;
  margin: 0.5rem;
}

.ContenedorLogo img{
  width: 80%;
  height: 10%;
  display: flex;
  border-radius: 10px;
}

.ContenedorBuscador{
  width: 55%;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  border-radius: 5px;
}

#inputBuscador{
  width: 92%;
  height: 100%;
  border: none;
  text-align: center;
  outline: none;
  font-size: 1rem;
  background: transparent;
}

#contenedorLupa{
  width: 8%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

#NavHeader{
  width: 28%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#containers{
  width: 100%;
  height: 70%;
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  background: transparent;
}

#logos{
  width: 25%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
}

.btnLogout{
  width: 100%;
  height: 100%;
  cursor: pointer;
  background: transparent;
  list-style: none;
  text-decoration: none;
  border: none;
  font-size: 1.05vw;
  font-style: oblique;
}

#TextIcons{
  width: 100%;
  height: 40%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: transparent;
}

#buttonNav{
  cursor: pointer;
  width: 2.3rem;
  height: 2.3rem;
  border: none;
  background: transparent;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#iconHeader{
  width: 100%;
  height: 100%;
  font-size: 35px;
  font-weight: 280;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Parte central */

.Principal{
  width: 100%;
  height: auto;
  background: transparent;
  display: flex;
}

/* Menu Lateral */

.containMenuLateral{
  width: 9.3%;
  height: 100%;
  transition: 0.5s all ease-in-out;
}
.containMenuLateral:hover{
  width: 12.8%;
  transition: 0.5s all ease-in-out;
}

.MenuLateralEstatico{
  width: 8.2%;
  height: 80%;
  transition: 0.5s all ease-in-out;
  position: fixed;
  left: 0;
  top: 125px;
}

.MenuLateralEstatico:hover{
  width: 11%;
  transition: 0.5s all ease-in-out;
}

.ContainerIconoBarras{
  width: 100%;
  height: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border-left: 4px solid transparent;
}

.ContainerAnclas{
  width: 100%;
  height: 65%;
  background: transparent;
}

.MenuAnclas{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.Ancla{
  width: 100%;
  height: 14.66%;
  background: transparent;
  list-style: none;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  border-left: 4px solid transparent;
  cursor: pointer;
}

.Ancla:hover{
  border-left: 4px solid #ff9c04;
}

.newWorkBtn{
  width: 100%;
  height: 14.66%;
  background: transparent;
  list-style: none;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  border: 4px solid transparent;
  cursor: pointer;
}

.newWorkBtn:hover{
  border-left: 3px solid #ff9c04;
}

.ContainerIconoConfiguracion{
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border-left: 4px solid transparent;
}

/* Centro */

.ContenidoCentral{
  width: 100%;
  height: auto;
  background: #e9e9e9;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  transition: 0.5s all ease-in-out;
  margin-top: 108px;
}

#contenidoNone{
  display: none;
}

.CartaT{
  width: 42%;
  height: 18rem;
  background: gray;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  margin: 1rem;
}

.work-item-info{
  width: 95%;
  height: 75%;
  display: flex;
  justify-content: space-evenly;
}

.work-image{
  width: 42%;
  height: 100%;
}

.imageWork{
  width: 100%;
  height: 100%;
}

.work-text{
  width: 58%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-left: 10px;
}

.work-buttons{
  width: 80%;
  height: 15%;
  display: flex;
  justify-content: space-evenly;
}

.work-buttons button{
  width: 25%;
  height: 100%;
  border-radius: 10px;
  cursor: pointer;
}
.anclaWorks{
  width: 40%;
  height: 100%;
  border-radius: 10px;
  font-size: 20px;
  font-weight: bold;
  color: black;
  background-color: #f6f5f5;
  border: 2px double orange;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* INICIO PERFIL */
.Profile{
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.containPerfil{
  width: 100%;
  height: 100%;
  display: flex;
}

#ProfileMain{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1vw;
}

#ProfileLeft{
  width: 48%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  background: #e4e4e4;
}

#UserProfileUp{
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  flex-direction: column;
  font-size: 35px;
}

#imgUser{
  width: 10rem;
  height: 10rem;
  border: 0.8rem white solid;
  border-radius: 50%;
  background-size: cover;
}

#UserProfileDown{
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#anchorsInfo{
  width: 60%;
  height: 60%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.AnInfo{
  width: 10rem;
  height: 3rem;
  background: #f9fafa;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  text-decoration: none;
  color: black;
  font-size: 18px;
}

#ProfileRight{
  width: 52%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 3rem;
  background: #f3f3f3;
}

.AnOptions{
  width: 70%;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  text-decoration: none;
  color: black;
  font-size: 32px;
  background: #cdcdcd;
  cursor: pointer;
}

/* MODAL */

.modalProfile{
  width: 400px;
  height: 68vh;
  background-color: orange;
  position: absolute;
  left: 17vw;
  top: 18vh;
  display: flex;
  flex-direction: column;
  font-size: 22px;
  align-items: center;
  padding: 7px;
}

.dateModal{
  width: 100%;
  height: 95%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

/* FORMULARIO */

.user-form{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.user-form__avatar{
  width: 80%;
  height: 25%;
  display: flex;
  justify-content: center;
}
.user-form__avatar .image{
  width: 36%;
  height: 95%;
  border-radius: 98%;
  border: dashed 3px rgb(9, 8, 5);
}

.user-form input{
  width: 26vw;
  height: 40px;
  font-size: 15px;
}

.formDivButton{
  width: 95%;
  height: 9.5%;
  display: flex;
  justify-content: space-evenly;
}

.formDivButton .button{
  width: 12vw;
  height: 100%;
  background-color: rgb(244, 241, 237);
  border: solid 1px white;
  border-radius: 10px;
  font-size: 16px;
  font-style: italic;
  cursor: pointer;
}

/* FIN PERFIL */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* INICIO LOGIN */
#Login{
  width: 100%;
  height: 100vh;
  background: radial-gradient(white,orange);
  display: flex;
}

.containerLogin{
  width: 100%;
  height: 100%;
  display: flex;
}

.LoginCard{
  width: 50%;
  height: 100%;
  background: linear-gradient(10deg, #f89e3d, #f9fafa);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.formLogin{
  width: 58%;
  height: 65%;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-top: 1.5vw;
  border-radius: 1vw;
  border: solid 2px orange;
}

.inputLogin{
  width: 78%;
  height: 11.5%;
  border-radius: 10px;
  border-style: none;
  font-weight: bold;
  font-family: 'DM Sans', sans-serif;
  font-family: 'Unbounded', cursive;
  border: 1px solid #faae5c;
  font-size: 1.2rem;
}

.btnLogin{
  width: 45%;
  height: 12%;
  border-radius: 10px;
  border: solid 1px orange;
  font-size: 1.2vw;
  font-style: oblique;
  background-color: #faae5c;
  cursor: pointer;
}

.informationLogin{
  width: 100%;
  height: 10%;
  display: flex;
  align-items: center;
}

.containImgLogin{
  width: 50%;
  height: 100%;
  background-color: #9a9a9a;
}
.imgLogin{
  width: 100%;
  height: 100%;
}

/* FIN LOGIN */

/* INICIO REGISTRO */

.Home{
  width: 100%;
  height: 100vh;
  display: flex;
}

.image{
  width: 48%;
  height: 100%;
  background-color: #9a9a9a;
}

.imgRegister{
  width: 100%;
  height: 100%;
  filter: brightness(50%);
}

.contentInfo{
  width: 52%;
  height: 100%;
  background-color: #f6f6f6;
  background: linear-gradient(10deg, #f89e3d, #f9fafa);
}

.imgBottomless{
  width: 20%;
}

.logoCompany{
  width: 100%;
  height: 20%;
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 0.8vw;
}

.TitlePrincipal{
  font-size: 2rem;
  letter-spacing: 10px;
}

.containerRegister{
  width: 100%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.containerPrincipal{
  width: 52%;
  height: 82%;
  background-color: white;
  border-radius: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  border: solid 2px #faae5c;
}

#InputsRegister{
  width: 100%;
  height: 85%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 2%;
  gap: 1rem ;
}

.anclasRegister{
  margin-top: 0.6vw;
}

h1{
  font-size: 2.7vw;
  font-family: 'DM Sans', sans-serif;
  font-family: 'Unbounded', cursive;
}

input{
  width: 85%;
  height: 30%;
  border-radius: 10px;
  border-style: none;
  font-weight: bold;
  font-family: 'DM Sans', sans-serif;
  font-family: 'Unbounded', cursive;
  border: 1px solid #faae5c;
  font-size: 1.2rem;
}

.BtnRegistere{
  width: 80%;
  height: 20vh;
  border-radius: 10px;
  border-style: none;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  transition: 0.5s;
  margin-top: 5%;
  font-family: 'DM Sans', sans-serif;
  font-family: 'Unbounded', cursive;
  background-color: #faae5c;
}

.BtnRegistere:hover{
  font-size: 1.8rem;
  transition: 0.5s;
}

@media (max-width:1024px){
  .containerPrincipal{
    width: 25%;
    height: 55%;
  }
}

@media (max-width:768px){
  .containerPrincipal{
    width: 35%;
    height: 55%;
  }
}

@media (max-width:425px){
  .containerPrincipal{
    width: 70%;
    height: 55%;
  }
}

@media (max-width:320px){
  .containerPrincipal{
    width: 90%;
    height: 55%;
  }
}

/* FIN REGISTRO */

/* POSTULADOS */

.pricipalPostulados{
  width: 100%;
}

.contenido{
  width: 100%;
  height: 86.8vh;
  display: flex;
}

.lateralpostulados{
  width: 8%;
  height: auto;
  background-color: rgb(255, 255, 255);
  transition: 0.5s all ease-in-out;
}

.lateralpostulados:hover{
  width: 11.7%;
  transition: 0.5s all ease-in-out;
}

.centralPostulados{
  width: 100%;
  height: 100%;
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 108px;
}

.peoplePostulation{
  width: 45%;
  height: 13%;
  background-color: white;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 2%;
  border-radius: 10px;
}

.paragraph{
  font-size: 2.7rem;
}

.imgPerson{
  width: 10%;
  height: 80%;
  border-radius: 40px;
  border: solid 1px black;
}

.seleccionar{
  width: 15%;
  height: 35%;
  border-radius: 10px;
  border-style: none;
  cursor: pointer;
  transition: 0.5s;
  background-color: #4bf101;
}

.seleccionar:hover{
  transition: 0.5s;
  background-color: #3bd1ff;
}

/* FIN POSTULADOS */

/* INICIO PROFILE POSTULATES */
.profilePostulates{
  width: 100%;
  height: 100%;
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 108px;
}

.cardProfile{
  width: 50%;
  height: 80%;
  background-color: #ff9c04;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  border-radius: 5%;
  border: double 4px #9e9b9b;
}

.imgProfilePostulate{
  width: 40%;
  height: 40%;
  border-radius: 100%;
}

/* FIN PROFILE POSTULATES */

/* INICO POSTULATION */
.viewPostulation{
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.containPostulation{
  width: 100%;
  height: 100%;
  display: flex;
}

.peoplePostulated{
  width: 100%;
  height: 100%;
  background: #e9e9e9;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  transition: 0.5s all ease-in-out;
  margin-top: 108px;
}

.buttonPostulates{
  width: 25%;
  height: 100%;
  border-radius: 10px;
  cursor: pointer;
  background-color: #e7e5e5;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: black;
  border: double 2px #2a2a2a;
}

/* FIN POSTULATION */

.material-symbols-rounded {
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 48
}

/* INICIO CHAT */
#Chat{
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

.containerChat{
  width: 100%;
  height: 100%;
  display: flex;
}

.worksChat{
  width: 30%;
  height: 84.3vh;
  background-color: white;
  border: solid 1px orange;
  margin-top: 10px;
  overflow:scroll;
  overflow-x:hidden;
}

.chatWorks{
  width: 100%;
  height: 14%;
  background-color: #dad7d7;
  border: solid 1px orange;
  margin-top: 0.1vw;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 1.5vw;
  cursor: pointer;
}

#messageChat{
  width: 67%;
  height: 75.6vh;
  background-color: #efefeb;
  margin-top: 10px;
  display: flex;
  padding-bottom: 5px;
  flex-direction: column;
  overflow:scroll;
  overflow-x:hidden;
}

.titleChat{
  width: 100%;
  height: 10%;
  background-color: #c4c5c4;
  display: flex;
  justify-content: center;
  align-items: center;
}
.titleMessage{
  font-size: 26px;
  font-weight: bold;
}

.messageWorks{
  width: 65%;
  height: auto;
  background-color: #87ff85d6;
  border-bottom: none;
  padding: 1vw;
  word-wrap: break-word;
  margin-top: 5px;
  margin-left: 2vw;
  border-radius: 0 12px 12px 12px;
}

.messageWorks2{
  width: 65%;
  height: auto;
  background-color: #efefeb;
  border-bottom: none;
  margin-left: 18.2vw;
  text-align: start;
  word-wrap: break-word;
  background-color: #32cdfc57;
  padding: 1vw;
  border-radius: 0 12px 12px 12px;
  margin-top: 5px;
}

.message{
  font-size: 1.2vw;
  word-wrap: break-word;
}

.sendMessage{
  width: 62.9%;
  height: 8%;
  display: flex;
  position: absolute;
  left: 37.1vw;
  bottom: 3px;
}

.inputMessage{
  width: 80%;
  height: 98%;
  border-radius: 5px;
  padding-left: 1vw;
  font-size: 1.4vw;
}

.buttonChat{
  width: 18%;
  height: 100%;
  border-radius: 6px;
  font-size: 1.5vw;
  cursor: pointer;
  border: solid 1px orange;
}

.buttonChat:hover{
  background-color: #b0aeae;
}

/* FIN CHAT */

/* ADMIN */
/* INICIO USERS */
#usersAdmin{
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.containerUsers{
  width: 100%;
  height: 100%;
  display: flex;
}

.users{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #dadddd;
}

.stateUsers{
  width: 70%;
  height: 95%;
  background-color: #767875;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px double;
}

.tabUsers{
  width: 90%;
  height: 95%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.tabUsers .ui.menu{
  width: 100%;
  height: 12%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.tabUsers .ui.menu .item {
  width: 46%;
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d8dbd7 !important;
  border-radius: 0 !important;
  border-top: 4px solid #ff9c04 !important;
  font-size: 25px;
  font-style: italic;
  cursor: pointer;
}

.user-item{
  width: 52.5vw;
  height: 60px;
  display: flex;
  background-color: #d8dbd7;
  justify-content: space-between;
  border: 1px solid #ff9c04;
  margin-bottom: 1px;
  padding: 10px;
  padding-left: 20px;
}
.btnIcon{
  width: 20%;
  height: 80%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.btnIcon button{
  width: 34%;
  height: 90%;
  cursor: pointer;
}

.btnIcon .button span{
  width: 100%;
  height: 100%;
  font-size: 37px;
}

#spanEdit1{
  background-color: #4bf101;
}
#spanEdit2{
  background-color: #f1a101;
}

#spanDrop{
  background-color: red;
  color: #ffffff;
}

.confirmModal{
  width: 30%;
  height: 25%;
  background-color: #3bd1ff;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  position: absolute;
  font-size: 20px;
  font-weight: bold;
  left: 620px;
  top: 200px;
}

.confirmModal button{
  width: 80px;
  height: 35px;
  border-radius: 10px;
  margin-right: 20px;
  margin-left: 20px;
  opacity: 10;
  cursor: pointer;
}

.user-item__info {
  display: flex;
  align-items: center;
}

.user-item .ui.image {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

/* FIN USERS */

/* INICIO WORKS */

#worksAdmin{
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.containerWorks{
  width: 100%;
  height: 100%;
  display: flex;
}

.works{
  width: 100%;
  height: 100%;
  background-color: #cacbc9;
  padding: 1vw;
}

/* FIN WORKS */

/* INICIO REPORTS */

#reportsAdmin{
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.containerReports{
  width: 100%;
  height: 100%;
  display: flex;
}

.reports{
  width: 100%;
  height: 100%;
  background-color: #cacbc9;
  padding: 1vw;
}

/* FIN REPORTS */